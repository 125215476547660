import { render, staticRenderFns } from "./FilialDefinirMetas.vue?vue&type=template&id=35bc0cd8&scoped=true"
import script from "./FilialDefinirMetas.vue?vue&type=script&lang=js"
export * from "./FilialDefinirMetas.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35bc0cd8",
  null
  
)

export default component.exports