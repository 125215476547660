<template>
	<v-card class="mt-3">
		<v-dialog v-model="dialogConfirmacao" persistent>
			<v-card class="pa-5">
				<v-card-title class="text-h5">
					<span class="mx-auto">Confirmar alteração de meta?</span>
				</v-card-title>
				<div class="text-center py-3">
					<v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
				</div>
				<v-card-text class="text-h6 text-center">Ao clicar em OK, você confirma que deseja alterar as metas da
					filial</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="red" elevation="0" class="px-5" dark
						@click="dialogConfirmacao = false">Cancelar</v-btn>
					<v-btn color="primary" elevation="0" class="px-10"
						@click="definirMeta(), dialogConfirmacao = false">OK</v-btn>
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog.status" persistent>
			<CardDialog :dialog="dialog" />
		</v-dialog>
		<v-row class="px-3 px-lg-0 mb-n7">
			<v-col class="d-none d-md-block">
				<v-card-title class="py-1">Definir metas</v-card-title>
			</v-col>
			<v-col cols="6" md="3" class="px-3 px-md-1 mb-n7 mb-md-0">
				<v-autocomplete :items="filiaisFiltro" item-text="filial" item-value="idfilial" label="Filial" outlined
					dense :disabled="carregando" v-model="busca.idfilial"
					@change="listarConfiguracaoMeta(), listar()"></v-autocomplete>
			</v-col>
			<v-col cols="6" md="3" class="px-3 px-md-1">
				<InputDatePicker type="month" :disabled="carregando" v-model="busca.dtmes" label="Data" :outlined="true"
					:dense="true" />
			</v-col>
			<v-col cols="12" md="3" class="px-3 px-md-1 mr-0 mr-md-5">
				<v-text-field :disabled="carregando" v-model="metaFilial" label="Meta Filial" outlined dense
					@blur="meta()" />
			</v-col>
		</v-row>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card-text>
			<p class="text-center text-h6 mt-5" v-if="dados.vendedores.length == 0">Selecione uma filial para listar as
				metas.</p>
			<v-simple-table v-else>
				<thead v-if="dados.mesmetaheader">
					<tr>
						<th></th>
						<th>Vendedor</th>
						<th>Meta {{ dados.mesmetaheader[0] }}</th>
						<th>Meta {{ dados.mesmetaheader[1] }}</th>
						<th>Meta {{ dados.mesmetaheader[2] }}</th>
						<th>Fixar valor</th>
						<th>Meta</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="dados.vendedores">
						<template v-for="(u, i) in dados.vendedores">
							<tr :key="i">
								<td class="pr-0">
									<v-btn fab text x-small @click="ocultarVendedor(u.cpf)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</td>
								<td class="text-truncate" style="max-width: 250px;">
									<v-icon color="red"
										v-if="u.grupo == 63 || u.grupo == 155">mdi-account-alert-outline</v-icon>
									{{ u.vendedor }}
									<br />
									<small>
										<strong>ID:</strong>
										{{ u.idvendedor }}
									</small>
									<small class="ml-3">
										<strong>Admissão:</strong>
										{{ u.dataadmissao }}
									</small>
								</td>
								<td class="text-truncate">
									{{ u.metas[0] | formataDinheiro }}
									<v-chip class="mt-1 font-weight-bold float-right" x-small
										:color="porcentagemMetaRealizada(u.realizadaspercentual[0])">{{
											u.realizadaspercentual[0].toFixed(1) }}%</v-chip>
									<br />
									<small>
										<strong>Realizado:</strong>
										{{ u.realizas[0] | formataDinheiro }}
									</small>
								</td>
								<td class="text-truncate">
									{{ u.metas[1] | formataDinheiro }}
									<v-chip class="mt-1 font-weight-bold float-right" x-small
										:color="porcentagemMetaRealizada(u.realizadaspercentual[1])">{{
											u.realizadaspercentual[1].toFixed(1) }}%</v-chip>
									<br />
									<small>
										<strong>Realizado:</strong>
										{{ u.realizas[1] | formataDinheiro }}
									</small>
								</td>
								<td class="text-truncate">
									{{ u.metas[2] | formataDinheiro }}
									<v-chip class="mt-1 font-weight-bold float-right" x-small
										:color="porcentagemMetaRealizada(u.realizadaspercentual[2])">{{
											u.realizadaspercentual[2].toFixed(1) }}%</v-chip>
									<br />
									<small>
										<strong>Realizado:</strong>
										{{ u.realizas[2] | formataDinheiro }}
									</small>
								</td>
								<td>
									<v-checkbox v-model="u.fixar" />
								</td>
								<td class="text-truncate">
									<v-text-field :color="u.dataDemissao ? 'red' : ''" class="mb-n3"
										style="min-width: 120px" :disabled="carregando" v-model="u.meta" dense
										:append-icon="u.dataDemissao ? 'mdi-alert-octagon-outline' : ''"
										@change="u.fixar = true, metaVendedor()" />
								</td>
							</tr>
							<tr :key="'v_' + u.idvendedor" class="py-0">
								<td colspan="7" class="text-truncate py-0"
									style="height: 30px !important; border-bottom: 1px solid #000 !important;">
									<small>
										<strong class="mr-2">Observação -</strong>
										<template v-if="u.dataDemissao">
											<strong>Demissão:</strong>
											{{ u.dataDemissao }}
										</template>
										<template v-if="u.dataDemissao && u.observacao">-</template>
										<template v-if="u.observacao">{{ u.observacao }}</template>
									</small>
								</td>
							</tr>
						</template>
					</template>
					<template v-else>
						<tr>
							<td class="text-center" colspan="7">Nenhum registro encontrado.</td>
						</tr>
					</template>
				</tbody>
				<tfoot v-if="dados.vendedores">
					<tr>
						<td></td>
						<td class="font-weight-bold">Total</td>
						<td class="font-weight-bold text-truncate">
							{{ dados.total.metas[0] | formataDinheiro }}
							<v-chip class="ml-1 font-weight-bold float-right" x-small
								:color="porcentagemMetaRealizada(dados.total.realizadaspercentual[0])">{{
									dados.total.realizadaspercentual[0].toFixed(2) }}%</v-chip>
							<br />
							<small>
								<strong>Realizado:</strong>
								{{ dados.total.realizas[0] | formataDinheiro }}
							</small>
						</td>
						<td class="font-weight-bold text-truncate">
							{{ dados.total.metas[1] | formataDinheiro }}
							<v-chip class="ml-1 font-weight-bold float-right" x-small
								:color="porcentagemMetaRealizada(dados.total.realizadaspercentual[1])">{{
									dados.total.realizadaspercentual[1].toFixed(2) }}%</v-chip>
							<br />
							<small>
								<strong>Realizado:</strong>
								{{ dados.total.realizas[1] | formataDinheiro }}
							</small>
						</td>
						<td class="font-weight-bold text-truncate">
							{{ dados.total.metas[2] | formataDinheiro }}
							<v-chip class="ml-1 font-weight-bold float-right" x-small
								:color="porcentagemMetaRealizada(dados.total.realizadaspercentual[2])">{{
									dados.total.realizadaspercentual[2].toFixed(2) }}%</v-chip>
							<br />
							<small>
								<strong>Realizado:</strong>
								{{ dados.total.realizas[2] | formataDinheiro }}
							</small>
						</td>
						<td></td>
						<td class="font-weight-bold text-truncate text-body-1">{{ totalMetaVendedor | formataDinheiro }}
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</v-card-text>
		<v-card-actions class="pa-4">
			<v-spacer />
			<v-btn class="py-5" :disabled="carregando" color="primary" elevation="0" @click="exportaPlanilhaTudo()">
				<v-icon class="pr-0 pr-md-2">mdi-file-excel-outline</v-icon>
				<span class="d-none d-md-block">Exportar Todas Filiais</span>
			</v-btn>
			<v-btn class="py-5" :disabled="carregando" color="primary" elevation="0" @click="exportaPlanilha()">
				<v-icon class="pr-0 pr-md-2">mdi-file-excel-outline</v-icon>
				<span class="d-none d-md-block">Exportar em Excel</span>
			</v-btn>
			<v-btn v-if="dados.metadefinida" class="py-5" :disabled="carregando" color="primary" elevation="0"
				@click="dialogConfirmacao = true">
				<v-icon class="pr-0 pr-md-2">mdi-content-save-outline</v-icon>
				<span class="d-none d-md-block">Definir Meta</span>
			</v-btn>
			<v-btn v-else class="py-5" :disabled="carregando" color="primary" elevation="0" @click="definirMeta()">
				<v-icon class="pr-0 pr-md-2">mdi-content-save-outline</v-icon>
				<span class="d-none d-md-block">Definir Meta</span>
			</v-btn>
		</v-card-actions>
		<v-progress-linear indeterminate absolute :active="carregando" />
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "FilialDefinirMetas",
	mixins: [mixinFilial],
	components: { InputDatePicker, CardDialog },
	data: () => ({
		dialog: { status: false, title: "", msg: "", icon: "", iconColor: "" },
		dialogConfirmacao: false,
		dialogVendedores: false,
		carregando: false,
		dados: { vendedores: [] },
		configuracoes: {},
		vendedoresOcultos: {},
		busca: {
			dtmes: `${new Date().getFullYear()}-${(new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getMonth() +
				1 <
				10
				? "0"
				: "") +
				(new Date().getMonth() + 1)
				}`,
			idfilial: 0,
		},
		metaFilial: "",
		totalMetaVendedor: 0.0,
		dataAtual: `${new Date().getFullYear()}-${(new Date(
			new Date().getFullYear(),
			new Date().getMonth() + 1,
			0
		).getMonth() +
			1 <
			10
			? "0"
			: "") +
			(new Date().getMonth() + 1)
			}-${(new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate() < 10
				? "0"
				: "") + new Date().getDate()
			}`,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listar() {
			if (!this.busca.idfilial) return;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}comissao/meta/listar`, {
					periodo: this.busca.dtmes || null,
					idfilial: this.busca.idfilial || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.metaFilial = 0;
					this.totalMetaVendedor = 0;
					if (!this.dados.vendedores) {
						this.dados.vendedores = [];
					}
					this.dados.vendedores.forEach((v) => {
						let dataAdmissao = new Date(
							v.dataadmissao.split("/").reverse().join("-")
						).getTime();
						let dataAtual = new Date(this.dataAtual).getTime();
						let diff = Math.abs(dataAdmissao - dataAtual);
						let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
						let month = days / 30;
						v.meses = month;

						let ano = parseInt(this.busca.dtmes.split("-")[0]);
						let mes = parseInt(this.busca.dtmes.split("-")[1]);

						let ultimoDiaMes = new Date(
							new Date(ano, mes).getFullYear(),
							new Date(ano, mes).getMonth(),
							0
						).getDate();
						let dataUltimoDiaMes = new Date(
							this.busca.dtmes + "-" + ultimoDiaMes + " 00:00:00"
						).getTime();
						let dataPrimeiroDiaMes = new Date(
							this.busca.dtmes + "-01 00:00:00"
						).getTime();

						let diffMounth = Math.abs(
							dataPrimeiroDiaMes - dataUltimoDiaMes
						);
						let daysMounth = Math.ceil(
							diffMounth / (1000 * 60 * 60 * 24) + 1
						);

						if (v.dataInicioFerias && v.dataFimFerias) {
							let dataIniFerias = new Date(
								v.dataInicioFerias
									.split("/")
									.reverse()
									.join("-")
							).getTime();
							let dataFinFerias = new Date(
								v.dataFimFerias.split("/").reverse().join("-")
							).getTime();

							if (
								dataIniFerias >= dataPrimeiroDiaMes &&
								dataIniFerias <= dataUltimoDiaMes &&
								dataFinFerias >= dataPrimeiroDiaMes &&
								dataFinFerias <= dataUltimoDiaMes
							) {
								let diff = Math.abs(
									dataFinFerias - dataIniFerias
								);
								v.diasdeferias =
									Math.ceil(diff / (1000 * 60 * 60 * 24)) + 1;
								v.diastrabalhados = daysMounth - v.diasdeferias;
							} else if (
								dataIniFerias >= dataPrimeiroDiaMes &&
								dataIniFerias <= dataUltimoDiaMes
							) {
								let diff = Math.abs(
									dataUltimoDiaMes - dataIniFerias
								);
								v.diasdeferias = Math.ceil(
									diff / (1000 * 60 * 60 * 24)
								);
								v.diastrabalhados = daysMounth - v.diasdeferias;
							} else if (
								dataFinFerias >= dataPrimeiroDiaMes &&
								dataFinFerias <= dataUltimoDiaMes
							) {
								let diff = Math.abs(
									dataFinFerias - dataPrimeiroDiaMes
								);
								v.diasdeferias =
									Math.ceil(diff / (1000 * 60 * 60 * 24)) + 1;
								v.diastrabalhados = daysMounth - v.diasdeferias;
							} else {
								v.diasdeferias = 0;
								v.diastrabalhados = daysMounth;
							}
						} else {
							v.diasdeferias = 0;
							v.diastrabalhados = daysMounth;
						}

						v.fixar = false;
						if (v.meta) {
							this.metaFilial += v.meta;
							this.totalMetaVendedor += v.meta;
							v.meta = parseFloat(v.meta).toLocaleString(
								"pt-br",
								{ style: "currency", currency: "BRL" }
							);
						} else {
							v.meta = 0;
							v.meta = parseFloat(v.meta).toLocaleString(
								"pt-br",
								{ style: "currency", currency: "BRL" }
							);
						}
						let totalMeta = 0;
						let meses = 0;
						v.metas.forEach((v) => {
							if (v != 0) {
								totalMeta += v;
								meses++;
							}
						});
						if (totalMeta != 0) {
							v.mediaMeta = totalMeta / meses;
						} else {
							v.mediaMeta = 0;
						}
						v.metas.forEach((v) => {
							if (v != 0) {
								totalMeta += v;
								meses++;
							}
						});
					});
					this.metaFilial = parseFloat(
						this.metaFilial
					).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					});
					this.carregando = false;
				})
				.catch(() => {
					this.dados = {
						vendedores: [{ vendedor: "Welinton " }, {}],
					};
					this.carregando = false;
				});
		},
		exportaPlanilha() {
			if (!this.busca.idfilial) return;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}comissao/meta/planilha/exportar`, {
					periodo: this.busca.dtmes || null,
					idfilial: this.busca.idfilial || null,
				})
				.then((res) => {
					this.carregando = false;
					const url = window.URL.createObjectURL(
						this.base64ToBlob(res.data)
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "arquivo vendedores.xlsx");
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		exportaPlanilhaTudo() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}comissao/meta/planilha/exportar`, {
					periodo: this.busca.dtmes || null,
					idfilial: 1,
					todas: true,
				})
				.then((res) => {
					this.carregando = false;
					const url = window.URL.createObjectURL(
						this.base64ToBlob(res.data)
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "arquivo vendedores.xlsx");
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		base64ToBlob(base64) {
			const decodedData = window.atob(base64);
			const uInt8Array = new Uint8Array(decodedData.length);
			for (let i = 0; i < decodedData.length; ++i) {
				uInt8Array[i] = decodedData.charCodeAt(i);
			}
			return new Blob([uInt8Array], { type: "text/plain" });
		},
		listarConfiguracaoMeta() {
			return axios
				.post(`${this.backendUrl}comissao/meta/configuracao`, {
					idfilial: this.busca.idfilial,
				})
				.then((res) => {
					this.configuracoes = res.data;
				});
		},
		porcentagemMetaRealizada(porcentagem) {
			if (porcentagem < 80) {
				return "red lighten-3";
			} else if (porcentagem >= 80 && porcentagem < 100) {
				return "yellow lighten-2";
			} else {
				return "green lighten-4";
			}
		},
		metaTotal(vendedores) {
			this.totalMetaVendedor = 0;
			if (vendedores) {
				vendedores.forEach((v) => {
					if (v.meta && v.meta != "R$ NaN") {
						let sugestao = parseFloat(
							v.meta
								.replaceAll("R$", "")
								.replaceAll(".", "")
								.replaceAll(",", ".")
						);
						this.totalMetaVendedor += sugestao;
						v.meta = parseFloat(sugestao).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						});
					}
				});
				this.metaFilial = parseFloat(
					this.totalMetaVendedor
				).toLocaleString("pt-br", {
					style: "currency",
					currency: "BRL",
				});
			} else {
				this.totalMetaVendedor = "";
			}
		},
		metaVendedor() {
			if (!this.dados || !Array.isArray(this.dados.vendedores)) {
				console.error("this.dados.vendedores não está definido ou não é um array.");
				return;
			}

			if (!this.configuracoes || !Array.isArray(this.configuracoes.lista)) {
				console.error("this.configuracoes.lista não está definido ou não é um array.");
				return;
			}

			try {
				this.dados.vendedores.forEach((v) => {
					if (
						v.meta == "" ||
						v.meta == "R$ NaN" ||
						v.meta == "R$ " ||
						v.meta == "R$"
					) {
						v.meta = "R$ 0,00";
					}
					v.meta = parseFloat(
						v.meta
							.replaceAll("R$", "")
							.replaceAll(".", "")
							.replaceAll(",", ".")
					);
				});
				if (this.metaFilial == "" || this.metaFilial == "R$ NaN") {
					this.metaFilial = "R$ 0,00";
				}
				this.metaFilial = parseFloat(
					this.metaFilial
						.replaceAll("R$", "")
						.replaceAll(".", "")
						.replaceAll(",", ".")
				);
				let vendedoresFixos = this.dados.vendedores.filter((v) => {
					return v.fixar;
				});
				let vendedoresDemitidos = this.dados.vendedores.filter((v) => {
					return v.dataDemissao && !v.fixar;
				});
				let vendedoresFerias = this.dados.vendedores.filter((v) => {
					return !v.dataDemissao && v.diasdeferias > 0 && !v.fixar;
				});
				let vendedoresAntigos = this.dados.vendedores.filter((v) => {
					return (
						!v.dataDemissao &&
						v.meses >
						this.configuracoes.lista
							.map((v) => v.meses)
							.reduce((a, b) => Math.max(a, b)) &&
						!v.fixar &&
						v.diasdeferias == 0
					);
				});
				let vendedoresNovos = this.dados.vendedores.filter((v) => {
					return (
						!v.dataDemissao &&
						v.meses <=
						this.configuracoes.lista
							.map((v) => v.meses)
							.reduce((a, b) => Math.max(a, b)) &&
						!v.fixar &&
						v.diasdeferias == 0
					);
				});
				let metaVendedores = 0;

				if (vendedoresDemitidos.length > 0) {
					vendedoresDemitidos.forEach((v) => {
						v.meta = 0;
					});
				}
				if (vendedoresNovos.length > 0) {
					vendedoresNovos.forEach((v) => {
						this.configuracoes.lista.forEach((c) => {
							if (v.meses > c.meses - 1 && v.meses <= c.meses) {
								v.meta = c.valor;
							}
						});
						metaVendedores += v.meta;
					});
				}

				if (vendedoresFerias.length > 0) {
					vendedoresFerias.forEach((v) => {
						if (v.mediaMeta > 0 && v.diastrabalhados > 0) {
							v.meta = parseFloat(
								(
									((v.mediaMeta / 30) * v.diastrabalhados) /
									10
								).toFixed(0) * 10
							);
						}
						metaVendedores += v.meta;
					});
				}

				if (vendedoresFixos.length > 0) {
					vendedoresFixos.forEach((v) => {
						metaVendedores += v.meta;
					});
				}

				if (this.metaFilial < metaVendedores) {
					this.metaFilial = metaVendedores;
				}

				let restoSobra = this.metaFilial - metaVendedores;
				let sobra = this.metaFilial - metaVendedores;

				let mediaMetaMesAnterior = 0;
				if (vendedoresAntigos.length > 0) {
					vendedoresAntigos.forEach((v) => {
						mediaMetaMesAnterior += v.mediaMeta;
					});
				}

				if (vendedoresAntigos.length > 0) {
					vendedoresAntigos.forEach((v) => {
						v.meta = parseFloat(
							(
								(sobra * v.mediaMeta) /
								mediaMetaMesAnterior /
								10
							).toFixed(0) * 10
						);
						restoSobra -= parseFloat(v.meta);
					});
					if (vendedoresAntigos[vendedoresAntigos.length - 1].meta != 0) {
						vendedoresAntigos[vendedoresAntigos.length - 1].meta =
							parseFloat(
								vendedoresAntigos[vendedoresAntigos.length - 1]
									.meta + restoSobra
							);
					} else {
						this.metaFilial = this.metaFilial - restoSobra;
					}
				}

				if (vendedoresAntigos.length > 0) {
					this.dados.vendedores = vendedoresAntigos;
				} else {
					this.dados.vendedores = [];
				}
				if (vendedoresDemitidos.length > 0) {
					vendedoresDemitidos.forEach((v) => {
						this.dados.vendedores.push(v);
					});
				}
				if (vendedoresNovos.length > 0) {
					vendedoresNovos.forEach((v) => {
						this.dados.vendedores.push(v);
					});
				}
				if (vendedoresFerias.length > 0) {
					vendedoresFerias.forEach((v) => {
						this.dados.vendedores.push(v);
					});
				}
				if (vendedoresFixos.length > 0) {
					vendedoresFixos.forEach((v) => {
						this.dados.vendedores.push(v);
					});
				}

				this.dados.vendedores.sort((a, b) => {
					return a.vendedor < b.vendedor
						? -1
						: a.vendedor > b.vendedor
							? 1
							: 0;
				});

				this.dados.vendedores.forEach((v) => {
					if (
						v.meta == "" ||
						v.meta == "R$ NaN" ||
						v.meta == "R$ " ||
						v.meta == "R$"
					) {
						v.meta = "R$ 0,00";
					} else {
						v.meta = parseFloat(v.meta).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						});
					}
				});

				this.metaFilial = parseFloat(this.metaFilial).toLocaleString(
					"pt-br",
					{
						style: "currency",
						currency: "BRL",
					}
				);
				this.metaTotal(this.dados.vendedores);
			}
			catch (error) {
				console.error("Erro ao processar os vendedores:", error);
			}
		},
		meta() {
			this.dados.vendedores.forEach((v) => {
				if (
					v.meta == "" ||
					v.meta == "R$ NaN" ||
					v.meta == "R$ " ||
					v.meta == "R$"
				) {
					v.meta = "R$ 0,00";
				}
				v.meta = parseFloat(
					v.meta
						.replaceAll("R$", "")
						.replaceAll(".", "")
						.replaceAll(",", ".")
				);
			});
			if (this.metaFilial == "" || this.metaFilial == "R$ NaN") {
				this.metaFilial = "R$ 0,00";
			}
			this.metaFilial = parseFloat(
				this.metaFilial
					.replaceAll("R$", "")
					.replaceAll(".", "")
					.replaceAll(",", ".")
			);
			if (this.metaFilial == 0) {
				this.dados.vendedores.map((v) => {
					return (v.meta = "R$ 0,00");
				});
				this.totalMetaVendedor = 0.0;
				this.metaFilial = "R$ 0,00";
			} else {
				let vendedoresFixos = this.dados.vendedores.filter((v) => {
					return v.fixar;
				});
				let vendedoresDemitidos = this.dados.vendedores.filter((v) => {
					return v.dataDemissao && !v.fixar;
				});
				let vendedoresFerias = this.dados.vendedores.filter((v) => {
					return !v.dataDemissao && v.diasdeferias > 0 && !v.fixar;
				});
				console.log(
					"chegou aqui",
					this.dados.vendedores,
					this.configuracoes.lista
				);
				let vendedoresAntigos = this.dados.vendedores.filter((v) => {
					return (
						!v.dataDemissao &&
						v.meses >
						this.configuracoes.lista
							.map((v) => v.meses)
							.reduce((a, b) => Math.max(a, b)) &&
						!v.fixar &&
						v.diasdeferias == 0
					);
				});
				console.log("passou aqui");
				let vendedoresNovos = this.dados.vendedores.filter((v) => {
					return (
						!v.dataDemissao &&
						v.meses <=
						this.configuracoes.lista
							.map((v) => v.meses)
							.reduce((a, b) => Math.max(a, b)) &&
						!v.fixar &&
						v.diasdeferias == 0
					);
				});
				let metaVendedores = 0;

				if (vendedoresDemitidos.length > 0) {
					vendedoresDemitidos.forEach((v) => {
						v.meta = 0;
					});
				}

				if (vendedoresNovos.length > 0) {
					vendedoresNovos.forEach((v) => {
						this.configuracoes.lista.forEach((c) => {
							if (v.meses > c.meses - 1 && v.meses <= c.meses) {
								v.meta = c.valor;
							}
						});
						metaVendedores += v.meta;
					});
				}

				if (vendedoresFerias.length > 0) {
					vendedoresFerias.forEach((v) => {
						if (v.mediaMeta > 0 && v.diastrabalhados > 0) {
							v.meta = parseFloat(
								(
									((v.mediaMeta / 30) * v.diastrabalhados) /
									10
								).toFixed(0) * 10
							);
						}
						metaVendedores += v.meta;
					});
				}

				if (vendedoresFixos.length > 0) {
					vendedoresFixos.forEach((v) => {
						metaVendedores += v.meta;
					});
				}

				if (this.metaFilial < metaVendedores) {
					this.metaFilial = metaVendedores;
				}

				let restoSobra = this.metaFilial - metaVendedores;
				let sobra = this.metaFilial - metaVendedores;

				let mediaMetaMesAnterior = 0;
				if (vendedoresAntigos.length > 0) {
					vendedoresAntigos.forEach((v) => {
						mediaMetaMesAnterior += v.mediaMeta;
					});
				}

				if (vendedoresAntigos.length > 0) {
					vendedoresAntigos.forEach((v) => {
						v.meta = parseFloat(
							(
								(sobra * v.mediaMeta) /
								mediaMetaMesAnterior /
								10
							).toFixed(0) * 10
						);
						restoSobra -= parseFloat(v.meta);
					});
					if (
						vendedoresAntigos[vendedoresAntigos.length - 1].meta !=
						0
					) {
						vendedoresAntigos[vendedoresAntigos.length - 1].meta =
							parseFloat(
								vendedoresAntigos[vendedoresAntigos.length - 1]
									.meta + restoSobra
							);
					} else {
						this.metaFilial = this.metaFilial - restoSobra;
					}
				}

				if (vendedoresAntigos.length > 0) {
					this.dados.vendedores = vendedoresAntigos;
				} else {
					this.dados.vendedores = [];
				}
				if (vendedoresDemitidos.length > 0) {
					vendedoresDemitidos.forEach((v) => {
						this.dados.vendedores.push(v);
					});
				}
				if (vendedoresNovos.length > 0) {
					vendedoresNovos.forEach((v) => {
						this.dados.vendedores.push(v);
					});
				}
				if (vendedoresFerias.length > 0) {
					vendedoresFerias.forEach((v) => {
						this.dados.vendedores.push(v);
					});
				}
				if (vendedoresFixos.length > 0) {
					vendedoresFixos.forEach((v) => {
						this.dados.vendedores.push(v);
					});
				}

				this.dados.vendedores.sort((a, b) => {
					return a.vendedor < b.vendedor
						? -1
						: a.vendedor > b.vendedor
							? 1
							: 0;
				});

				this.dados.vendedores.forEach((v) => {
					if (
						v.meta == "" ||
						v.meta == "R$ NaN" ||
						v.meta == "R$ " ||
						v.meta == "R$"
					) {
						v.meta = "R$ 0,00";
					} else {
						v.meta = parseFloat(v.meta).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						});
					}
				});

				this.metaFilial = parseFloat(this.metaFilial).toLocaleString(
					"pt-br",
					{
						style: "currency",
						currency: "BRL",
					}
				);
				this.metaTotal(this.dados.vendedores);
			}
		},
		definirMeta() {
			let vendedores = [];
			this.dados.vendedores.forEach((v) => {
				let vendedor = {};
				vendedor.idvendedor = v.idvendedor;
				vendedor.vendedor = v.vendedor;
				if (v.meta == "R$ NaN") {
					v.meta = "R$ 0,00";
				}
				if (v.meta) {
					vendedor.meta = parseFloat(
						v.meta
							.replaceAll("R$", "")
							.replaceAll(".", "")
							.replaceAll(",", ".")
					);
				} else {
					vendedor.meta = parseFloat(0);
				}
				vendedores.push(vendedor);
			});
			return axios
				.post(`${this.backendUrl}comissao/meta/cadastrar`, {
					idfilial: this.busca.idfilial,
					periodo: this.busca.dtmes,
					metafilial: parseFloat(
						this.metaFilial
							.replaceAll("R$", "")
							.replaceAll(".", "")
							.replaceAll(",", ".")
					),
					vendedores: vendedores,
				})
				.then((res) => {
					if (res.data) {
						this.dialog.title = "Meta definida";
						this.dialog.msg =
							"Meta dos vendedores definida com sucesso!";
						this.dialog.icon = "mdi-check-circle-outline";
						this.dialog.iconColor = "success";
						this.dialog.status = true;
						this.listar();
					} else {
						this.dialog.title = "Erro";
						this.dialog.msg =
							"Não foi possível definir a meta da filial!";
						this.dialog.icon = "mdi-alert-circle-outline";
						this.dialog.iconColor = "red";
						this.dialog.status = true;
					}
				})
				.catch(() => {
					this.dialog.title = "Erro";
					this.dialog.msg =
						"Não foi possível definir a meta da filial!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
					this.dialog.status = true;
				});
		},
	},
	watch: {
		"busca.dtmes": function (newValue, oldValue) {
			if (this.busca.dtmes < "2022-01") {
				this.dialog.status = true;
				this.dialog.title = "Erro";
				this.dialog.msg =
					"A data não pode ser inferior a Janeiro de 2022!";
				this.dialog.icon = "mdi-alert-circle-outline";
				this.dialog.iconColor = "red";
				this.busca.dtmes = oldValue;
			} else {
				this.listarConfiguracaoMeta();
				this.listar();
			}
		},
	},
	created() { },
};
</script>

<style scoped></style>